<template>
  <div>
    <div class="page-header panel">
      <div class="action-section">
        <el-button
          v-if="!mArrayIncludes(role, ['TL', 'CC', 'other'])"
          style="background-color: #1d6f42; color: #fff"
          size="small"
          icon="el-icon-download"
          @click="onexport()"
          plain
          >Татаж авах (.xlsx)</el-button
        >
      </div>
    </div>
    <div class="panel">
      <el-row>
        <el-col :span="21">
          <el-form
            :inline="true"
            class="demo-form-inline"
            size="mini"
            v-model="filterform"
          >
            <el-row :gutter="20">
              <el-col :span="24">
                <el-form-item label="Харилцагчийн нэр">
                  <el-input
                    placeholder="Харилцагчийн нэр"
                    size="mini"
                    @change="
                      getOutlets(
                        paginationObject.pageSize,
                        paginationObject.currentPage,
                        filterform.serviceFilterValue,
                        filterform.searchOutletName,
                        filterform.outlet_status,
                        filterform.is_verified,
                        filterform.is_pickup,
                        filterform.is_delivery_free,
                        filterform.is_preOrder,
                        filterform.ebarimt,
                        filterform.is_delivery_by_outlet,
                        filterform.product_name,
                        filterform.contract_is_paid
                      )
                    "
                    v-model="filterform.searchOutletName"
                  />
                </el-form-item>
                <el-form-item label="Үйлчилгээ">
                  <el-select
                    placeholder="Үйлчилгээ"
                    v-model="filterform.serviceFilterValue"
                    size="mini"
                  >
                    <el-option value="" key="all" label="Бүгд"></el-option>
                    <el-option
                      value="food13"
                      key="food13"
                      label="тест"
                    ></el-option>
                    <el-option
                      v-for="(service, index) in servicesList"
                      :label="service.name_mon"
                      :value="service.name_eng"
                      :key="index"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="Бүтээгдэхүүнээр хайх">
                  <el-input
                    placeholder="хайх..."
                    size="mini"
                    @change="
                      getOutlets(
                        paginationObject.pageSize,
                        paginationObject.currentPage,
                        filterform.serviceFilterValue,
                        filterform.searchOutletName,
                        filterform.outlet_status,
                        filterform.is_verified,
                        filterform.is_pickup,
                        filterform.is_delivery_free,
                        filterform.is_preOrder,
                        filterform.ebarimt,
                        filterform.is_delivery_by_outlet,
                        filterform.product_name,
                        filterform.contract_is_paid
                      )
                    "
                    v-model="filterform.product_name"
                  />
                </el-form-item>
                <el-form-item v-if="isOpen" label="Нээлттэй эсэх">
                  <el-select
                    clearable
                    placeholder="Сонгох"
                    v-model="filterform.outlet_status"
                    size="mini"
                  >
                    <el-option
                      value="open"
                      key="open"
                      label="Нээлттэй"
                    ></el-option>
                    <el-option
                      value="close"
                      key="close"
                      label="Хаалттай"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item v-if="isVerified" label="Гэрээ байгуулсан эсэх">
                  <el-switch
                    v-model="filterform.is_verified"
                    active-color="#13ce66"
                  ></el-switch>
                </el-form-item>
                <el-form-item>
                  <el-button
                    type="primary"
                    icon="el-icon-search"
                    @click="
                      getOutlets(
                        paginationObject.pageSize,
                        paginationObject.currentPage,
                        filterform.serviceFilterValue,
                        filterform.searchOutletName,
                        filterform.outlet_status,
                        filterform.is_verified,
                        filterform.is_pickup,
                        filterform.is_delivery_free,
                        filterform.is_preOrder,
                        filterform.ebarimt,
                        filterform.is_delivery_by_outlet,
                        filterform.product_name,
                        filterform.contract_is_paid
                      )
                    "
                    >Хайх</el-button
                  >
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item v-if="isPickUp" label="Очиж авах эсэх">
                  <el-switch
                    v-model="filterform.is_pickup"
                    active-color="#13ce66"
                  ></el-switch>
                </el-form-item>
                <el-form-item
                  v-if="isDeliveryFree"
                  label="Хүргэлтийн төлбөр үнэгүй эсэх"
                >
                  <el-switch
                    v-model="filterform.is_delivery_free"
                    active-color="#13ce66"
                  ></el-switch>
                </el-form-item>
                <el-form-item v-if="isPreOrder" label="Урьдчилсан захиалга">
                  <el-switch
                    v-model="filterform.is_preOrder"
                    active-color="#13ce66"
                  ></el-switch>
                </el-form-item>
                <el-form-item
                  v-if="isEbarimt"
                  label="Ebarimt-г манайх гаргах эсэх"
                >
                  <el-switch
                    v-model="filterform.ebarimt"
                    active-color="#13ce66"
                  ></el-switch>
                </el-form-item>
                <el-form-item
                  v-if="contract_is_paid"
                  label="Суурь хураамж төлсөн эсэх"
                >
                  <el-switch
                    v-model="filterform.contract_is_paid"
                    active-color="#13ce66"
                  ></el-switch>
                </el-form-item>
                <el-form-item
                  v-if="isDeliveryByOutlet"
                  label="Харилцагч өөрөө хүргэх эсэх"
                >
                  <el-switch
                    v-model="filterform.is_delivery_by_outlet"
                    active-color="#13ce66"
                  ></el-switch>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-col>
        <el-col :span="3">
          <el-button type="primary" @click="searchOption" plain
            >Хайлтын сонголтууд</el-button
          >
        </el-col>
      </el-row>
      <el-table :data="outletsList">
        <el-table-column type="index" width="50"> </el-table-column>
        <el-table-column prop="full_name_mon" width="200" label="Нэр">
          <template slot-scope="scope">
            <el-button type="text" @click="open(scope.row._id)">{{
              scope.row.full_name_mon
            }}</el-button>
          </template>
        </el-table-column>
        <el-table-column
          prop="outlet_status_mon"
          label="Төлөв"
          :filters="filters"
          :filter-method="filterTag"
          width="130px"
        >
          <template slot-scope="scope">
            <div slot="reference" class="name-wrapper">
              <el-switch
                :value="
                  outletsList[scope.$index].outlet_status == 'open'
                    ? true
                    : false
                "
                @change="openDialog(scope.row._id)"
                active-color="#13ce66"
                inactive-color="#ff4949"
                :disabled="
                  !mArrayIncludes(role, ['admin', 'boss', 'menu_editor'])
                "
              />
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="Хаяг" width="400">
        </el-table-column>
        <el-table-column
          v-if="mArrayIncludes(role, ['ops'])"
          prop="percent"
          label="Шимтгэлийн хувь"
          width="150px"
        >
          <template slot-scope="scope">
            <el-tag size="mini" type="danger">{{ scope.row.percent }}% </el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="closed_food_count"
          label="Бүтээгдэхүүн"
          width="120px"
        >
          <template slot-scope="scope">
            <div slot="reference" class="name-wrapper">
              <el-tag size="mini" style="margin-right: 5px" type="danger"
                >{{
                  scope.row.closed_food_count > 0
                    ? scope.row.closed_food_count
                    : 0
                }}
              </el-tag>
              <el-tag size="mini" style="margin-left: 5px" type="success"
                >{{
                  scope.row.open_food_count > 0 ? scope.row.open_food_count : 0
                }}
              </el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="Цэс"
          width="100px"
          v-if="
            mArrayIncludes(role, [
              'ST',
              'SM',
              'admin',
              'client_care',
              'CC',
              'TL',
              'boss'
            ])
          "
        >
          <template slot-scope="scope">
            <el-button
              class="partner-button"
              type="primary"
              size="mini"
              icon="el-icon-edit"
              @click="editMenu(scope.row)"
            ></el-button>
          </template>
        </el-table-column>
        <el-table-column
          v-if="role.indexOf('supervisor') > -1"
          prop="outlet_status_mon"
          label="Үйлдэл"
          :filters="filters"
          :filter-method="filterTag"
          width="100px"
        >
          <template slot-scope="scope">
            <div slot="reference" class="name-wrapper">
              <el-button
                class="partner-button"
                v-if="scope.row.outlet_status == 'open'"
                size="mini"
                type="danger"
                @click="changeStatus(scope.row._id)"
              ></el-button>
              <el-button
                v-else-if="scope.row.outlet_status == 'close'"
                class="partner-button"
                size="mini"
                type="warning"
                @click="changeStatus(scope.row._id)"
              ></el-button>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          v-if="!mArrayIncludes(role, ['other'])"
          prop="zip"
          label="Үйлдэл"
          width="100px"
        >
          <template slot-scope="scope">
            <el-button
              class="partner-button"
              type="danger"
              size="mini"
              icon="el-icon-edit"
              @click="getOutletById(scope.row._id)"
            ></el-button>
          </template>
        </el-table-column>
        <el-table-column
          v-if="
            mArrayIncludes(role, ['boss', 'ST', 'SM', 'admin', 'client_care'])
          "
          prop="zip"
          label="Хуулах"
          width="100px"
        >
          <template slot-scope="scope">
            <el-button
              class="partner-button"
              type="success"
              size="mini"
              icon="el-icon-copy-document"
              @click="copy(scope.row._id)"
              disabled
            ></el-button>
          </template>
        </el-table-column>
        <el-table-column label="Түүх" width="100px">
          <template slot-scope="scope">
            <el-button
              class="partner-button"
              type="info"
              size="mini"
              @click="log(scope.row._id)"
              icon="el-icon-timer"
            >
            </el-button>
          </template>
        </el-table-column>
        <el-table-column
          v-if="
            mArrayIncludes(role, ['boss', 'ST', 'SM', 'admin', 'client_care'])
          "
          label="Устгах"
          width="100px"
          :filters="[
            { text: 'Устгах', value: false },
            { text: 'Сэргээх', value: true }
          ]"
          :filter-method="actionFilterTag"
          filter-placement="bottom-end"
        >
          <template slot-scope="scope">
            <el-button
              class="partner-button"
              v-if="!scope.row.deleted"
              type="warning"
              size="mini"
              @click="deletePartner(scope.row._id)"
              icon="el-icon-delete"
            ></el-button>
            <el-button
              class="partner-button"
              v-else-if="scope.row.deleted"
              type="success"
              size="mini"
              @click="restorePartner(scope.row._id)"
              icon="el-icon-refresh-left"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-dialog
        title="Түүх харах"
        :visible.sync="dialogBool"
        @close="close"
        @closed="close"
        width="80%"
        center
      >
        <span>
          <el-table size="mini" :data="logs" style="width: 95%">
            <el-table-column prop="action" label="Үйлдэл"> </el-table-column>
            <el-table-column prop="desc" label="Тайлбар"> </el-table-column>
            <el-table-column prop="user" label="Хэн"> </el-table-column>
            <el-table-column
              prop="created_at"
              label="Хэзээ"
              width="180"
              sortable
            >
            </el-table-column>
            <el-table-column prop=".old.is_active" label="Хуучин төлөв">
              <template slot-scope="scope">
                <div slot="reference" class="name-wrapper">
                  <el-tag
                    v-if="scope.row.old.is_active"
                    size="mini"
                    type="success"
                    >Хэвийн</el-tag
                  >
                  <el-tag v-else size="mini" type="danger">Хаасан</el-tag>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="new.is_active" label="Шинэ төлөв">
              <template slot-scope="scope">
                <div slot="reference" class="name-wrapper">
                  <el-tag
                    v-if="scope.row.new.is_active"
                    size="mini"
                    type="success"
                    >Хэвийн</el-tag
                  >
                  <el-tag v-else size="mini" type="danger">Хаасан</el-tag>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="Түүх харах">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  type="danger"
                  @click="seeLogDetail(scope.row)"
                  >Дэлгэрэнгүй</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            class="mt20 text-right"
            background
            @size-change="handleSizeChangeLog"
            @current-change="
              handleCurrentChangeLogs(paginationObjectLogs.currentPage)
            "
            :current-page.sync="paginationObjectLogs.currentPage"
            :page-size="paginationObjectLogs.size"
            layout="sizes, prev, pager, next"
            :total="paginationObjectLogs.total"
          >
          </el-pagination>
        </span>
        <span slot="footer" class="dialog-footer">
          <el-button size="mini" @click="close" type="danger">Хаах</el-button>
        </span>
      </el-dialog>
      <el-pagination
        class="mt20 text-right"
        background
        @size-change="handleSizeChange"
        @current-change="updateNav(paginationObject.currentPage)"
        :current-page.sync="paginationObject.currentPage"
        :page-sizes="[25, 50, 100, 200]"
        :page-size="paginationObject.pageSize"
        layout="sizes, prev, pager, next"
        :total="paginationObject.totalRecord"
      >
      </el-pagination>
    </div>
    <el-dialog
      title="Ресторан хуулах"
      :visible.sync="copyDialog"
      :before-close="handleClose"
    >
      <el-form
        ref="copyForm"
        :model="copyRes"
        label-width="150px"
        :rules="copyFormRules"
      >
        <el-form-item label="Монгол нэр" prop="full_name_mon">
          <el-input v-model="copyRes.full_name_mon"></el-input>
        </el-form-item>
        <el-form-item label="Англи нэр" prop="full_name_eng">
          <el-input v-model="copyRes.full_name_eng"></el-input>
        </el-form-item>
        <el-form-item label="Хаяг - апп" prop="address">
          <el-input v-model="copyRes.address"></el-input>
        </el-form-item>
        <el-form-item label="Хувийн хаяг" prop="address">
          <el-input v-model="copyRes.private_address"></el-input>
        </el-form-item>
        <el-form-item label="Утасны дугаар">
          <el-table
            :data="copyRes.phone_list"
            style="width: 100%"
            max-height="350"
          >
            <el-table-column prop="name" label="Албан тушаал" width="120">
              <template slot-scope="scope">
                <span>{{ scope.row.name }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="phone" label="Утас" width="125">
              <template slot-scope="scope">
                <el-input
                  size="small"
                  placeholder="99119911"
                  style="text-align:center"
                  type="number"
                  :min="8"
                  :max="8"
                  v-model.number="scope.row.phone"
                ></el-input>
              </template> </el-table-column
            ><el-table-column prop="email" label="Цахим шуудан" width="180">
              <template slot-scope="scope">
                <el-input
                  size="small"
                  placeholder="email@toktok.mn"
                  style="text-align:center"
                  type="text"
                  v-model="scope.row.email"
                ></el-input>
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
        <el-form-item label="Хүргэлтийн бүс" prop="description">
          <el-input v-model="copyRes.description"></el-input>
        </el-form-item>
        <el-form-item label="Үйлчилгээ" prop="service_list">
          <el-select
            placeholder="Үйлчилгээ"
            v-model="copyRes.service_list"
            multiple
            clearable
          >
            <el-option
              v-for="(service, index) in servicesList"
              :label="service.name_mon"
              :value="service.name_eng"
              :key="index"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">Буцах</el-button>
        <el-button type="primary" @click="copyReq">Хуулах</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="Рестораны дэлгэрэнгүй"
      :visible.sync="dialogShow"
      width="30%"
      center
    >
      <h3>
        <li><b>Рестораны нэр: </b></li>
      </h3>
      <span>{{ popUp.outlet_name }}</span>
      <h3>
        <li><b>Хүргэлтийн бүс: </b></li>
      </h3>
      <span>{{ selectedOutlet.description }}</span>
      <h3>
        <li><b>Байршил:</b></li>
      </h3>
      <span>{{ selectedOutlet.address }}</span>
      <h3>
        <li><b>Холбоо барих:</b></li>
      </h3>
      <span>{{ selectedOutlet.phone }}</span>
      <h3>
        <li><b>Мэйл хаяг:</b></li>
      </h3>
      <span v-if="selectedOutlet.email">{{ selectedOutlet.email }}</span>
      <span v-if="!selectedOutlet.email"
        >Бүртгэлтэй мэйл хаяг байхгүй байна.</span
      >
    </el-dialog>
    <div v-if="showLogSeeDetailDialog">
      <log-see-detail-dialog
        :seeLogData="this.logSeeDetailData"
        :showLogSeeDetailDialog="showLogSeeDetailDialog"
      ></log-see-detail-dialog>
    </div>
    <el-dialog
      title="Та энэхүү харилцагчийг устгахдаа итгэлтэй байна уу?"
      :visible.sync="deleteDialog"
      width="30%"
    >
      <span>Ямар шалтгаанаар устгаж байна вэ?</span>
      <el-input
        style="margin-top: 20px"
        type="textarea"
        autosize
        placeholder="Тайлбар"
        v-model="deleteDesc"
      >
      </el-input>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="deleteClose()">Үгүй</el-button>
        <el-button size="mini" type="success" @click="deleteConfirmFunction()"
          >Тийм</el-button
        >
      </span>
    </el-dialog>
    <el-dialog :visible.sync="searchVisible" title="Хайлтын сонголтууд">
      <div>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-checkbox border v-model="isOpen"
              >Нээлттэй эсэх</el-checkbox
            ></el-col
          >
          <el-col :span="8">
            <el-checkbox border v-model="isPreOrder"
              >Урьдчилсан захиалга</el-checkbox
            ></el-col
          >
          <el-col :span="8">
            <el-checkbox border v-model="isDeliveryByOutlet"
              >Харилцагч өөрөө хүргэх эсэх</el-checkbox
            ></el-col
          >
        </el-row>
        <br />
        <el-row :gutter="20">
          <el-col :span="8">
            <el-checkbox border v-model="isPickUp"
              >Очиж авах эсэх</el-checkbox
            ></el-col
          >
          <el-col :span="8">
            <el-checkbox border v-model="isVerified"
              >Гэрээ байгуулсан эсэх</el-checkbox
            ></el-col
          >
          <el-col :span="8">
            <el-checkbox border v-model="isDeliveryFree"
              >Хүргэлтийн төлбөр үнэгүй эсэх</el-checkbox
            ></el-col
          >
        </el-row>
        <br />
        <el-row :gutter="20">
          <el-col :span="8">
            <el-checkbox border v-model="isEbarimt"
              >Ebarimt-г манайх гаргах эсэх</el-checkbox
            ></el-col
          >
          <el-col :span="8">
            <el-checkbox border v-model="contract_is_paid">
              Суурь хураамж төлсөн
            </el-checkbox>
          </el-col>
        </el-row>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          size="mini"
          type="primary"
          icon="el-icon-search"
          @click="searchFilter"
          >Сонгох</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="Зассан шалтгаан бичнэ үү!!!"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <el-input
        v-model="reasonEdit"
        type="textarea"
        placeholder="Зассан шалтгаан"
      />
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog">Cancel</el-button>
        <el-button type="primary" @click="changeStatus(outlet_id)"
          >Confirm</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import service from "@/helpers/services.js";
import { getUserName, getGroups } from "../utils/auth";
import LogSeeDetailDialog from "./LogSeeDetailDialog.vue";
import { bus } from "../main";
import XLSX from "xlsx";
import { arrayIncludes } from "../helpers/helper";

export default {
  name: "PartnerList",
  components: {
    LogSeeDetailDialog
  },
  created() {
    getUserName().then(name => {
      this.user = name.attributes.email.split("@")[0];
    });
    if (this.$route.query.page) {
      this.paginationObject.currentPage = parseInt(this.$route.query.page);
    } else {
      this.$route.query.page = 1;
      this.paginationObject.currentPage = 1;
    }
    this.getServices();
    getGroups().then(data => {
      this.role = data;
      if (this.role.includes("address_editor")) {
        this.$router.push({
          path: "/list-address"
        });
      }
    });
    this.getOutlets(
      this.paginationObject.pageSize,
      this.paginationObject.currentPage,
      this.filterform.serviceFilterValue,
      this.filterform.searchOutletName,
      this.filterform.outlet_status,
      this.filterform.is_verified,
      this.filterform.is_pickup,
      this.filterform.is_delivery_free,
      this.filterform.is_preOrder,
      this.filterform.ebarimt,
      this.filterform.is_delivery_by_outlet,
      this.filterform.product_name,
      this.filterform.contract_is_paid
    );
    bus.$on("closeLogDialog", data => {
      this.logSeeDetailData = {};
      this.showLogSeeDetailDialog = false;
      this.testSend = data;
    });
  },
  methods: {
    seeLogDetail(data) {
      this.logSeeDetailData = data;
      this.showLogSeeDetailDialog = true;
    },
    changePage(a) {
      this.currentPage = parseInt(a);
      this.from = (parseInt(a) - 1) * parseInt(this.size);
      this.log(this.outletId);
    },
    onexport() {
      service.downloadOutlets().then(res => {
        if (res.data.status === "success") {
          this.downloadOutletList = res.data.data;
          var reportXlsx = XLSX.utils.json_to_sheet(res.data.data);
          var wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, reportXlsx, "report");
          var currentdate = new Date();
          var datetime =
            currentdate.getFullYear() +
            "_" +
            currentdate.getDate() +
            "_" +
            (currentdate.getMonth() + 1) +
            " " +
            currentdate.getHours() +
            "_" +
            currentdate.getMinutes() +
            "_" +
            currentdate.getSeconds();
          XLSX.writeFile(wb, datetime + ".xlsx");
        }
      });
    },
    actionFilterTag(value, row) {
      return row.deleted === value;
    },
    open(id) {
      const payload = { includeFields: [] };
      service.getOutletById(id, payload).then(response => {
        this.selectedOutlet = response;
        this.popUp.outlet_name = response.data.data.full_name_eng;
        this.dialogShow = true;
      });
    },
    openDialog(id) {
      this.dialogVisible = true;
      this.outlet_id = id;
    },
    closeDialog() {
      this.reasonEdit = "";
      this.dialogVisible = false;
    },
    changeStatus(id) {
      const payload = { includeFields: [] };
      service.getOutletById(id, payload).then(outletResponse => {
        const tempOutletData = JSON.parse(
          JSON.stringify(outletResponse.data.data)
        );
        if (tempOutletData.hasOwnProperty("outlet_name")) {
          this.$confirm(
            tempOutletData.outlet_name +
              " рестораны төлөвийг өөрчлөхдөө итгэлтэй байна уу?",
            "Анхаар ",
            {
              confirmButtonText: "Тийм",
              cancelButtonText: "Үгүй",
              type: "warning"
            }
          )
            .then(() => {
              if (tempOutletData.is_active === true) {
                tempOutletData._id = id;
                tempOutletData.user = this.user;
                tempOutletData.is_active = false;
                tempOutletData.desc = this.reasonEdit;
                service.editPartner(id, tempOutletData).then(response => {
                  if (response.data.status == "updated") {
                    this.closeDialog();
                    this.$message({
                      message: "Төлөв амжилттай өөрчлөгдлөө",
                      type: "success"
                    });
                  }
                  this.getOutlets(
                    this.paginationObject.pageSize,
                    this.paginationObject.currentPage,
                    this.filterform.serviceFilterValue,
                    this.filterform.searchOutletName,
                    this.filterform.outlet_status,
                    this.filterform.is_verified,
                    this.filterform.is_pickup,
                    this.filterform.is_delivery_free,
                    this.filterform.is_preOrder,
                    this.filterform.ebarimt,
                    this.filterform.is_delivery_by_outlet,
                    this.filterform.product_name,
                    this.filterform.contract_is_paid
                  );
                });
              } else {
                tempOutletData._id = id;
                tempOutletData.user = this.user;
                tempOutletData.outlet_status = "open";
                tempOutletData.is_active = true;
                tempOutletData.outlet_status_eng = "Open";
                tempOutletData.outlet_status_mon = "Нээлттэй";
                tempOutletData.desc = this.reasonEdit;
                service.editPartner(id, tempOutletData).then(response => {
                  if (response.data.status == "updated") {
                    this.$message({
                      message: "Төлөв амжилттай өөрчлөгдлөө.",
                      type: "success"
                    });
                  }
                  this.getOutlets(
                    this.paginationObject.pageSize,
                    this.paginationObject.currentPage,
                    this.filterform.serviceFilterValue,
                    this.filterform.searchOutletName,
                    this.filterform.outlet_status,
                    this.filterform.is_verified,
                    this.filterform.is_pickup,
                    this.filterform.is_delivery_free,
                    this.filterform.is_preOrder,
                    this.filterform.ebarimt,
                    this.filterform.is_delivery_by_outlet,
                    this.filterform.product_name,
                    this.filterform.filterform
                  );
                });
              }
              this.closeDialog();
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "Хүсэлт цуцлагдлаа"
              });
            });
        } else {
          this.$message.error(
            "Уучлаарай, Таны сонгосон харилцагчийн мэдээллийг татаж авч чадсангүй."
          );
        }
      });
    },
    deleteClose() {
      this.deleteDialog = false;
      this.deleteDesc = "";
      this.deletePartnerId = null;
    },
    deletePartner(id) {
      this.deleteDialog = true;
      this.deletePartnerId = id;
    },
    deleteConfirmFunction() {
      if (this.deleteDesc === "") {
        this.$message.error("Тайлбар заавал оруулна уу!!!.");
      } else {
        let payload = { desc: this.deleteDesc };
        service.deletePartner(this.deletePartnerId, payload).then(data => {
          this.getOutlets(
            this.paginationObject.pageSize,
            this.paginationObject.currentPage,
            this.filterform.serviceFilterValue,
            this.filterform.searchOutletName,
            this.filterform.outlet_status,
            this.filterform.is_verified,
            this.filterform.is_pickup,
            this.filterform.is_delivery_free,
            this.filterform.is_preOrder,
            this.filterform.ebarimt,
            this.filterform.is_delivery_by_outlet,
            this.filterform.product_name,
            this.filterform.contract_is_paid
          );
          this.$message({
            type: "success",
            message: data.result
          });
          this.deleteClose();
        });
      }
    },
    restorePartner(id) {
      this.$confirm(
        "Энэ харилцагчийг сэргээхдээ итгэлтэй байна уу?",
        "Анхааруулга",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning"
        }
      )
        .then(() => {
          let payload = {
            desc: "hooson bn"
          };
          service.restorePartner(id, payload).then(data => {
            this.getOutlets(
              this.paginationObject.pageSize,
              this.paginationObject.currentPage,
              this.filterform.serviceFilterValue,
              this.filterform.searchOutletName,
              this.filterform.outlet_status,
              this.filterform.is_verified,
              this.filterform.is_pickup,
              this.filterform.is_delivery_free,
              this.filterform.is_preOrder,
              this.filterform.ebarimt,
              this.filterform.is_delivery_by_outlet,
              this.filterform.product_name,
              this.filterform.contract_is_paid
            );
            this.$message({
              type: "success",
              message: data.result
            });
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Delete canceled"
          });
        });
    },
    log(id) {
      this.outletId = id;
      service
        .getRestLoggs(
          id,
          this.paginationObjectLogs.size,
          this.paginationObjectLogs.from
        )
        .then(data => {
          this.logs = data.data.data;
          this.paginationObjectLogs.total = data.data.total;
        });
      this.dialogBool = true;
    },
    copyReq() {
      this.$refs.copyForm.validate(valid => {
        if (valid) {
          if (this.copyRes.phone_list.length <= 0) {
            if (this.copyRes.phone_list.length <= 0) {
              return this.$notify.error({
                title: "Error",
                message:
                  "Ядаж нэг хүний утасны дугаар болон албан тушаал бичих ёстой!!!"
              });
            }
            if (this.copyRes.phone_list.length > 0) {
              this.copyRes.phone_list.map((el, index) => {
                if (
                  (el.phone == null || el.phone == "") &&
                  (el.name == null || el.name == "") &&
                  (el.email == null || el.email == "")
                ) {
                  this.copyRes.phone_list.splice(index, 1);
                }
              });
              if (
                this.copyRes.phone_list.find(
                  el => el.phone === null || el.phone === ""
                )
              )
                return this.$notify.error({
                  title: "Error",
                  message: "Утасны дугаар бичээгүй байна!!!"
                });
              if (
                this.copyRes.phone_list.find(
                  el => el.name === null || el.name === ""
                )
              )
                return this.$notify.error({
                  title: "Error",
                  message: "Албан тушаалын бичээгүй байна!!!"
                });
              if (
                this.copyRes.phone_list.find(
                  el => el.email === null || el.email === ""
                )
              )
                return this.$notify.error({
                  title: "Error",
                  message: "Цахим хаяг бичээгүй байна!!!"
                });
            }
            return this.$notify.error({
              title: "Error",
              message:
                "Ядаж нэг хүний утасны дугаар болон албан тушаал бичих ёстой!!!"
            });
          }
          if (this.copyRes.phone_list.length > 0) {
            this.copyRes.phone_list.map((el, index) => {
              if (
                (el.phone == null || el.phone == "") &&
                (el.name == null || el.name == "") &&
                (el.email == null || el.email == "")
              ) {
                this.copyRes.phone_list.splice(index, 1);
              }
            });
            if (
              this.copyRes.phone_list.find(
                el => el.phone === null || el.phone === ""
              )
            )
              return this.$notify.error({
                title: "Error",
                message: "Утасны дугаар бичээгүй байна!!!"
              });
            if (
              this.copyRes.phone_list.find(
                el => el.name === null || el.name === ""
              )
            )
              return this.$notify.error({
                title: "Error",
                message: "Албан тушаалын бичээгүй байна!!!"
              });
            if (
              this.copyRes.phone_list.find(
                el => el.email === null || el.email === ""
              )
            )
              return this.$notify.error({
                title: "Error",
                message: "Цахим хаяг бичээгүй байна!!!"
              });
          }
          service.copyPartner(this.copyRes).then(data => {
            if (data.status === "success") {
              this.$notify({
                title: "Мэдэгдэл",
                message: data.message,
                type: data.status
              });
              this.copyRes.full_name_mon = "";
              this.copyRes.full_name_eng = "";
              this.copyRes.address = "";
              this.copyRes.description = "";
              // this.copyRes.service = "";
              this.copyRes.service_list = [];
              this.copyRes.private_address = "";
              this.copyRes.phone_list = [
                {
                  phone: null,
                  name: "Захиалга",
                  email: ""
                },
                {
                  phone: null,
                  name: "Менежер",
                  email: ""
                },
                {
                  phone: null,
                  name: "Санхүү",
                  email: ""
                },
                {
                  phone: null,
                  name: "Захирал",
                  email: ""
                }
              ];
              this.copyDialog = false;
            } else {
              this.$notify({
                title: "Мэдэгдэл",
                message: data.message,
                type: data.status
              });
            }
          });
        } else {
          return false;
        }
      });
    },
    handleClose(done) {
      this.$confirm("Бичигдсэн юмнууд чинь устна шүү ок уу?")
        .then(ok => {
          this.copyRes.full_name_mon = "";
          this.copyRes.full_name_eng = "";
          this.copyRes.address = "";
          this.copyRes.description = "";
          // this.copyRes.service = "";
          this.copyRes.service_list = [];
          this.copyRes.private_address = "";
          this.copyRes.phone_list = [
            {
              phone: null,
              name: "Захиалга",
              email: ""
            },
            {
              phone: null,
              name: "Менежер",
              email: ""
            },
            {
              phone: null,
              name: "Санхүү",
              email: ""
            },
            {
              phone: null,
              name: "Захирал",
              email: ""
            }
          ];
          (this.copyDialog = false), ok === "" ? done() : done();
        })
        .catch(er => {
          this.$notify({
            title: "Error",
            message: er.message,
            type: "warning"
          });
        });
    },
    copy(id) {
      this.copyRes.id = id;
      this.copyDialog = true;
    },
    updateNav(id) {
      this.$router.push({
        name: "main",
        query: {
          page: id
        }
      });
      this.handleCurrentChange(id);
    },
    filterTag(value, row) {
      return row.outlet_status_mon === value;
    },
    filterTagOwner(value, row) {
      return row.owner === value;
    },
    editMenu(partner) {
      const routeData = this.$router.resolve({
        path: "menu",
        query: { outlet_id: partner._id }
      });
      window.open(routeData.href, "_blank");
    },
    async getServices() {
      service.getServices(false, "", "").then(servicesResponse => {
        let b = servicesResponse.data.data.map(el => {
          el.name_eng = el.name_eng.toLowerCase();
          el.name_mon = el.name_mon.toLowerCase();
          return el;
        });
        this.servicesList = b;
      });
    },
    getOutlets(
      pageSize,
      currentPage,
      service_list,
      outlet_name,
      outlet_status,
      is_verified,
      is_pickup,
      is_delivery_free,
      is_preOrder,
      ebarimt,
      is_delivery_by_outlet,
      product_name,
      contract_is_paid
    ) {
      let fromRecord = (parseInt(currentPage) - 1) * parseInt(pageSize);
      if (service_list === "all") service_list = null;
      const payload = {
        pageSize,
        fromRecord,
        service: service_list,
        outlet_name,
        outlet_status,
        is_verified,
        is_pickup,
        is_delivery_free,
        is_preOrder,
        ebarimt,
        contract_is_paid,
        is_delivery_by_outlet,
        product_name,
        includeFields: this.includeFields
      };
      service.getOutlets(payload).then(outletResponse => {
        this.outletsList = outletResponse.data.data;
        this.paginationObject.totalRecord = outletResponse.data.total;
      });
    },
    getOutletById(id) {
      const routeData = this.$router.resolve({
        path: "partner-info",
        query: { _id: id }
      });
      window.open(routeData.href, "_blank");
    },
    close() {
      this.paginationObjectLogs.from = 0;
      this.paginationObjectLogs.currentPage = 1;
      this.paginationObjectLogs.size = 10;
      this.dialogBool = false;
    },
    handleSizeChangeLog(val) {
      this.paginationObjectLogs.size = val;
      this.log(this.outletId);
    },
    handleSizeChange(val) {
      this.paginationObject.pageSize = val;
      this.getOutlets(
        this.paginationObject.pageSize,
        this.paginationObject.currentPage,
        this.filterform.serviceFilterValue,
        this.filterform.searchOutletName,
        this.filterform.outlet_status,
        this.filterform.is_verified,
        this.filterform.is_pickup,
        this.filterform.is_delivery_free,
        this.filterform.is_preOrder,
        this.filterform.ebarimt,
        this.filterform.is_delivery_by_outlet,
        this.filterform.product_name,
        this.filterform.contract_is_paid
      );
    },
    handleCurrentChange(val) {
      this.paginationObject.currentPage = val;
      this.getOutlets(
        this.paginationObject.pageSize,
        this.paginationObject.currentPage,
        this.filterform.serviceFilterValue,
        this.filterform.searchOutletName,
        this.filterform.outlet_status,
        this.filterform.is_verified,
        this.filterform.is_pickup,
        this.filterform.is_delivery_free,
        this.filterform.is_preOrder,
        this.filterform.ebarimt,
        this.filterform.is_delivery_by_outlet,
        this.filterform.product_name,
        this.filterform.contract_is_paid
      );
    },
    handleCurrentChangeLogs(val) {
      this.paginationObjectLogs.currentPage = val;
      this.paginationObjectLogs.from = val - 1;
      this.log(this.outletId);
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    mArrayIncludes(base_array, searchArray) {
      return arrayIncludes(base_array, searchArray);
    },
    searchOption() {
      this.searchVisible = true;
    },
    searchFilter() {
      this.isOpen
        ? (this.filterform.outlet_status = "open")
        : (this.filterform.outlet_status = "");
      this.isPreOrder
        ? (this.filterform.is_preOrder = true)
        : (this.filterform.is_preOrder = "");
      this.isDeliveryByOutlet
        ? (this.filterform.is_delivery_by_outlet = true)
        : (this.filterform.is_delivery_by_outlet = "");
      this.isPickUp
        ? (this.filterform.is_pickup = true)
        : (this.filterform.is_pickup = "");
      this.isVerified
        ? (this.filterform.is_verified = true)
        : (this.filterform.is_verified = "");
      this.isDeliveryFree
        ? (this.filterform.is_delivery_free = true)
        : (this.filterform.is_delivery_free = "");
      this.isEbarimt
        ? (this.filterform.ebarimt = true)
        : (this.filterform.ebarimt = "");
      this.contract_is_paid
        ? (this.filterform.contract_is_paid = true)
        : (this.filterform.contract_is_paid = "");
      this.searchVisible = false;
    }
  },
  data() {
    return {
      searchVisible: false,
      isOpen: false,
      isPreOrder: false,
      isDeliveryByOutlet: false,
      isPickUp: false,
      isVerified: false,
      isDeliveryFree: false,
      isEbarimt: false,
      contract_is_paid: false,
      deletePartnerId: null,
      deleteDesc: "",
      testSend: false,
      deleteDialog: false,
      outletRequestCurrentPage: 1,
      showLogSeeDetailDialog: false,
      logSeeDetailData: {},
      outletRequestPageItemLimit: 10,
      outletRequestPageCount: 0,
      currentPage: 1,
      pageItemLimit: 10,
      pageCount: 0,
      user: "",
      statusConvertData: {},
      role: "",
      logs: [],
      dialogBool: false,
      copyFormRules: {
        full_name_mon: [
          {
            required: true,
            message: "Харилцагчийн монгол нэрийг оруулаагүй байна.",
            trigger: "blur"
          }
        ],
        full_name_eng: [
          {
            required: true,
            message: "Харилцагчийн англи нэрийг оруулаагүй байна.",
            trigger: "blur"
          }
        ],
        phone: [
          {
            required: true,
            message: "Харилцагчийн утасны дугаарыг оруулаагүй байна.",
            trigger: "blur"
          }
        ],
        address: [
          {
            required: true,
            message: "Харилцагчийн хаягийг оруулаагүй байна.",
            trigger: "blur"
          }
        ],
        description: [
          {
            required: true,
            message: "Харилцагчийн хүргэлтийн бүсийг оруулаагүй байна.",
            trigger: "blur"
          }
        ],
        service_list: [
          {
            required: true,
            message: "Үйлчилгээ сонгоогүй байна",
            trigger: "change"
          }
        ]
      },
      copyRes: {
        full_name_mon: "",
        full_name_eng: "",
        address: "",
        description: "",
        // service: "",
        service_list: [],
        private_address: "",
        phone_list: [
          {
            phone: null,
            name: "Захиалга",
            email: ""
          },
          {
            phone: null,
            name: "Менежер",
            email: ""
          },
          {
            phone: null,
            name: "Санхүү",
            email: ""
          },
          {
            phone: null,
            name: "Захирал",
            email: ""
          }
        ]
      },
      copyDialog: false,
      filters: [
        {
          text: "Нээлттэй",
          value: "Нээлттэй"
        },
        {
          text: "Хаалттай",
          value: "Хаалттай"
        }
      ],
      owner: [
        {
          value: "Мөнхцацрал",
          label: "Мөнхцацрал"
        },
        {
          value: "Номин",
          label: "Номин"
        },
        {
          value: "Бямбацэрэн",
          label: "Бямбацэрэн"
        },
        {
          value: "Энхбилэг",
          label: "Энхбилэг"
        }
      ],
      paginationObject: {
        totalRecord: 0,
        currentPage: 1,
        pageSize: 25
      },
      paginationObjectLogs: {
        total: 0,
        from: 0,
        currentPage: 1,
        size: 10
      },
      filterform: {
        searchOutletName: "",
        serviceFilterValue: "food",
        outlet_status: "",
        is_verified: "",
        is_pickup: "",
        is_delivery_free: "",
        is_preOrder: "",
        ebarimt: "",
        is_delivery_by_outlet: "",
        product_name: "",
        contract_is_paid: ""
      },
      servicesList: [],
      outletsList: [],
      stats: {},
      dialogShow: false,
      selectedOutlet: [],
      popUp: {
        outlet_name: "",
        outlet_id: ""
      },
      monthlyStat: [],
      outerVisible: false,
      innerVisible: false,
      requestData: [],
      outletRequestInfoDetail: {},
      outletOldData: {},
      polygonDialog: false,
      scheduleDialog: false,
      downloadOutletList: [],
      statsService: [],
      totalStats: {},
      allStats: {
        services: []
      },
      allServices: null,
      includeFields: [],
      size: 20,
      from: 0,
      outletId: "",
      total: 0,
      outlet_id: "",
      dialogVisible: false,
      reasonEdit: ""
    };
  }
};
</script>
<style lang="scss" scoped>
.page-header {
  margin-top: 20px;
}

.item {
  margin-top: 10px;
  margin-right: 10px;
}

.partner-button {
  margin-top: 10px;
}

.request-button {
  margin: 0px;
}

.partner-google-map-dialog {
  width: 100%;
  height: 500px;
  display: inline-flex;
  background-color: #ccc;
}
</style>
