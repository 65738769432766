<template>
  <div>
    <el-dialog
      title="Түүхийн дэлгэрэнгүй"
      :visible.sync="showLogSeeDetailDialog"
      :before-close="closeDialog"
      width="90%"
    >
      <el-tabs v-model="activeName">
        <el-tab-pane label="Ерөнхий мэдээлэл" name="first">
          <el-row :gutter="20">
            <el-form
              v-show="
                JSON.stringify(seeLogData.old.img_url) !==
                  JSON.stringify(seeLogData.new.img_url)
              "
            >
              <el-col :span="12">
                <el-form-item label="Харилцагчийн сервис ">
                  <base64-upload
                    style="width: 100px; height: 100px"
                    :imageSrc="seeLogData.old.img_url"
                  ></base64-upload>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Харилцагчийн сервис шинэ">
                  <el-input
                    v-model="seeLogData.new.service"
                    disabled
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-form>
            <el-form
              v-show="
                JSON.stringify(seeLogData.old.service) !==
                  JSON.stringify(seeLogData.new.service)
              "
            >
              <el-col :span="12">
                <el-form-item label="Харилцагчийн сервис ">
                  <el-input
                    v-model="seeLogData.old.service"
                    disabled
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Харилцагчийн сервис шинэ">
                  <el-input
                    v-model="seeLogData.new.service"
                    disabled
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-form>
            <el-form
              v-show="
                JSON.stringify(seeLogData.old.is_active) !==
                  JSON.stringify(seeLogData.new.is_active)
              "
            >
              <el-col :span="12">
                <el-form-item label="Төлөв:">
                  <el-checkbox disabled v-model="seeLogData.old.is_active">
                    Нээлттэй эсэх
                  </el-checkbox>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Төлөв шинэ: ">
                  <el-checkbox disabled v-model="seeLogData.new.is_active">
                    Нээлттэй эсэх
                  </el-checkbox>
                </el-form-item>
              </el-col>
            </el-form>
            <el-form
              v-show="
                JSON.stringify(seeLogData.old.is_draft) !==
                  JSON.stringify(seeLogData.new.is_draft)
              "
            >
              <el-col :span="12">
                <el-form-item label="Апп дээр харагдах:">
                  <el-checkbox disabled v-model="seeLogData.old.is_draft">
                    Нуух эсэх
                  </el-checkbox>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Апп дээр харагдах шинэ :">
                  <el-checkbox disabled v-model="seeLogData.new.is_draft">
                    Нуух эсэх
                  </el-checkbox>
                </el-form-item>
              </el-col>
            </el-form>
            <el-form
              v-show="
                JSON.stringify(seeLogData.old.is_special) !==
                  JSON.stringify(seeLogData.new.is_special)
              "
            >
              <el-col :span="12">
                <el-form-item label="Онцлох:">
                  <el-checkbox disabled v-model="seeLogData.old.is_special">
                    Онцлох эсэх
                  </el-checkbox>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Онцлох шинэ :">
                  <el-checkbox disabled v-model="seeLogData.new.is_special">
                    Онцлох эсэх
                  </el-checkbox>
                </el-form-item>
              </el-col>
            </el-form>
            <el-form
              v-show="
                JSON.stringify(seeLogData.old.ebarimt) !==
                  JSON.stringify(seeLogData.new.ebarimt)
              "
            >
              <el-col :span="12">
                <el-form-item label="E-barimt:">
                  <el-checkbox disabled v-model="seeLogData.old.ebarimt">
                    Ebarimt-г манайх гаргах эсэх
                  </el-checkbox>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="E-barimt шинэ :">
                  <el-checkbox disabled v-model="seeLogData.new.ebarimt">
                    Ebarimt-г манайх гаргах эсэх
                  </el-checkbox>
                </el-form-item>
              </el-col>
            </el-form>
            <el-form
              v-show="
                JSON.stringify(seeLogData.old.is_pickup) !==
                  JSON.stringify(seeLogData.new.is_pickup)
              "
            >
              <el-col :span="12">
                <el-form-item label="Очиж авах захиалга:">
                  <el-checkbox disabled v-model="seeLogData.old.is_pickup">
                    Очиж авах захиалга
                  </el-checkbox>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Очиж авах захиалга шинэ:">
                  <el-checkbox disabled v-model="seeLogData.new.is_pickup">
                    Очиж авах захиалга
                  </el-checkbox>
                </el-form-item>
              </el-col>
            </el-form>
            <!-- <el-form
              v-show="
                JSON.stringify(seeLogData.old.is_child) !==
                  JSON.stringify(seeLogData.new.is_child)
              "
            >
              <el-col :span="12">
                <el-form-item label="Толгой харилцагчтай эсэх:">
                  <el-checkbox disabled v-model="seeLogData.old.is_child">
                    Толгой харилцагчтай эсэх
                  </el-checkbox>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Толгой харилцагчтай эсэх шинэ:">
                  <el-checkbox disabled v-model="seeLogData.new.is_child">
                    Толгой харилцагчтай эсэх
                  </el-checkbox>
                </el-form-item>
              </el-col>
            </el-form> -->
            <el-form
              v-show="
                JSON.stringify(seeLogData.old.full_name_mon) !==
                  JSON.stringify(seeLogData.new.full_name_mon)
              "
            >
              <el-col :span="12">
                <el-form-item label="Харилцагчийн монгол нэр">
                  <el-input
                    v-model="seeLogData.old.full_name_mon"
                    disabled
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Харилцагчийн монгол нэр шинэ">
                  <el-input
                    v-model="seeLogData.new.full_name_mon"
                    disabled
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-form>
            <el-form
              v-show="
                JSON.stringify(seeLogData.old.full_name_eng) !==
                  JSON.stringify(seeLogData.new.full_name_eng)
              "
            >
              <el-col :span="12">
                <el-form-item label="Харилцагчийн ангил нэр">
                  <el-input
                    v-model="seeLogData.old.full_name_eng"
                    disabled
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Харилцагчийн ангил нэр шинэ">
                  <el-input
                    v-model="seeLogData.new.full_name_eng"
                    disabled
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-form>
            <el-form
              v-show="
                JSON.stringify(seeLogData.old.phone_list) !==
                  JSON.stringify(seeLogData.new.phone_list)
              "
            >
              <el-col :span="12">
                <el-form-item label="Утас">
                  <el-table
                    :data="seeLogData.old.phone_list"
                    style="width: 100%"
                    max-height="350"
                  >
                    <el-table-column
                      prop="name"
                      label="Албан тушаал"
                      width="150"
                    >
                      <template slot-scope="scope">
                        <el-input
                          size="small"
                          style="text-align:center"
                          v-model="scope.row.name"
                          disabled
                          controls-position="right"
                        ></el-input>
                      </template>
                    </el-table-column>
                    <el-table-column prop="phone" label="Утас" width="150">
                      <template slot-scope="scope">
                        <el-input
                          size="small"
                          style="text-align:center"
                          type="number"
                          disabled
                          v-model.number="scope.row.phone"
                        ></el-input>
                      </template>
                    </el-table-column>
                  </el-table>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Утас шинэ">
                  <el-table
                    :data="seeLogData.new.phone_list"
                    style="width: 100%"
                    max-height="350"
                  >
                    <el-table-column
                      prop="name"
                      label="Албан тушаал"
                      width="150"
                    >
                      <template slot-scope="scope">
                        <el-input
                          size="small"
                          style="text-align:center"
                          v-model="scope.row.name"
                          disabled
                          controls-position="right"
                        ></el-input>
                      </template>
                    </el-table-column>
                    <el-table-column prop="phone" label="Утас" width="150">
                      <template slot-scope="scope">
                        <el-input
                          size="small"
                          style="text-align:center"
                          type="number"
                          disabled
                          v-model.number="scope.row.phone"
                        ></el-input>
                      </template>
                    </el-table-column>
                  </el-table>
                </el-form-item>
              </el-col>
            </el-form>
            <el-form
              v-show="
                JSON.stringify(seeLogData.old.address) !==
                  JSON.stringify(seeLogData.new.address)
              "
            >
              <el-col :span="12">
                <el-form-item label="Хаяг">
                  <el-input
                    v-model="seeLogData.old.address"
                    disabled
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Хаяг шинэ">
                  <el-input
                    v-model="seeLogData.new.address"
                    disabled
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-form>
            <el-form
              v-show="
                JSON.stringify(seeLogData.old.description) !==
                  JSON.stringify(seeLogData.new.description)
              "
            >
              <el-col :span="12">
                <el-form-item label="Хаягийн дэлгэрэнгүй">
                  <el-input
                    v-model="seeLogData.old.description"
                    disabled
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Хаягийн дэлгэрэнгүй шинэ">
                  <el-input
                    v-model="seeLogData.new.description"
                    disabled
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-form>
            <el-form
              v-show="
                JSON.stringify(seeLogData.old.email) !==
                  JSON.stringify(seeLogData.new.email)
              "
            >
              <el-col :span="12">
                <el-form-item label="Мэйл хаяг">
                  <el-input v-model="seeLogData.old.email" disabled></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Мэйл хаяг шинэ">
                  <el-input v-model="seeLogData.new.email" disabled></el-input>
                </el-form-item>
              </el-col>
            </el-form>
            <el-form
              v-show="
                JSON.stringify(seeLogData.old.img_url) !==
                  JSON.stringify(seeLogData.new.img_url)
              "
            >
              <el-col :span="12">
                <el-form-item label="Зураг:">
                  <el-image
                    style="width: 100px; height: 100px"
                    :src="seeLogData.old.img_url"
                  >
                  </el-image>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Зураг шинэ:">
                  <el-image
                    style="width: 100px; height: 100px"
                    :src="seeLogData.new.img_url"
                  >
                  </el-image>
                </el-form-item>
              </el-col>
            </el-form>
            <el-form
              v-show="
                JSON.stringify(seeLogData.old.main_category_mon) !==
                  JSON.stringify(seeLogData.new.main_category_mon)
              "
            >
              <el-col :span="12">
                <el-form-item label="Үндсэн ангилал">
                  <el-select
                    size="mini"
                    v-model="seeLogData.old.main_category_mon"
                    multiple
                    filterable
                    placeholder="Select"
                    disabled
                  >
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Үндсэн ангилал шинэ">
                  <el-select
                    size="mini"
                    v-model="seeLogData.new.main_category_mon"
                    multiple
                    filterable
                    placeholder="Select"
                    disabled
                  >
                  </el-select>
                </el-form-item>
              </el-col>
            </el-form>
            <el-form
              v-show="
                JSON.stringify(seeLogData.old.main_category_eng) !==
                  JSON.stringify(seeLogData.new.main_category_eng)
              "
            >
              <el-col :span="12">
                <el-form-item label="Үндсэн ангилал англи">
                  <el-select
                    size="mini"
                    v-model="seeLogData.old.main_category_eng"
                    multiple
                    filterable
                    placeholder="Select"
                    disabled
                  >
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Үндсэн ангилал англи шинэ">
                  <el-select
                    size="mini"
                    v-model="seeLogData.new.main_category_eng"
                    multiple
                    filterable
                    placeholder="Select"
                    disabled
                  >
                  </el-select>
                </el-form-item>
              </el-col>
            </el-form>
            <el-form
              v-show="
                JSON.stringify(seeLogData.old.search_category_mon) !==
                  JSON.stringify(seeLogData.new.search_category_mon)
              "
            >
              <el-col :span="12">
                <el-form-item label="Хайх ангилал">
                  <el-select
                    size="mini"
                    v-model="seeLogData.old.search_category_mon"
                    multiple
                    filterable
                    placeholder="Select"
                    disabled
                  >
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Хайх ангилал шинэ">
                  <el-select
                    size="mini"
                    v-model="seeLogData.new.search_category_mon"
                    multiple
                    filterable
                    placeholder="Select"
                    disabled
                  >
                  </el-select>
                </el-form-item>
              </el-col>
            </el-form>
            <el-form
              v-show="
                JSON.stringify(seeLogData.old.search_category_eng) !==
                  JSON.stringify(seeLogData.new.search_category_eng)
              "
            >
              <el-col :span="12">
                <el-form-item label="Хайх ангилал англи">
                  <el-select
                    size="mini"
                    v-model="seeLogData.old.search_category_eng"
                    multiple
                    filterable
                    placeholder="Select"
                    disabled
                  >
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Хайх ангилал англи шинэ">
                  <el-select
                    size="mini"
                    v-model="seeLogData.new.search_category_eng"
                    multiple
                    filterable
                    placeholder="Select"
                    disabled
                  >
                  </el-select>
                </el-form-item>
              </el-col>
            </el-form>
            <el-form
              v-show="
                JSON.stringify(seeLogData.old.menu_category_mon) !==
                  JSON.stringify(seeLogData.new.menu_category_mon)
              "
            >
              <el-col :span="12">
                <el-form-item label="Харилцагчийн өөрийн цэс mn">
                  <el-select
                    size="mini"
                    v-model="seeLogData.old.menu_category_mon"
                    multiple
                    filterable
                    placeholder="Select"
                    disabled
                  >
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Харилцагчийн өөрийн цэс mn шинэ">
                  <el-select
                    size="mini"
                    v-model="seeLogData.new.menu_category_mon"
                    multiple
                    filterable
                    placeholder="Select"
                    disabled
                  >
                  </el-select>
                </el-form-item>
              </el-col>
            </el-form>
            <el-form
              v-show="
                JSON.stringify(seeLogData.old.menu_category_eng) !==
                  JSON.stringify(seeLogData.new.menu_category_eng)
              "
            >
              <el-col :span="12">
                <el-form-item label="Харилцагчийн өөрийн цэс eng">
                  <el-select
                    size="mini"
                    v-model="seeLogData.old.menu_category_eng"
                    multiple
                    filterable
                    placeholder="Select"
                    disabled
                  >
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Харилцагчийн өөрийн цэс eng шинэ">
                  <el-select
                    size="mini"
                    v-model="seeLogData.new.menu_category_eng"
                    multiple
                    filterable
                    placeholder="Select"
                    disabled
                  >
                  </el-select>
                </el-form-item>
              </el-col>
            </el-form>
            <el-form
              v-show="
                JSON.stringify(seeLogData.old.service_list) !==
                  JSON.stringify(seeLogData.new.service_list)
              "
            >
              <el-col :span="12">
                <el-form-item label="Харилцагчийн өөрийн цэс eng">
                  <el-select
                    size="mini"
                    v-model="seeLogData.old.service_list"
                    placeholder="Select"
                    disabled
                  >
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Харилцагчийн өөрийн цэс eng шинэ">
                  <el-select
                    size="mini"
                    v-model="seeLogData.new.service_list"
                    placeholder="Select"
                    disabled
                  >
                  </el-select>
                </el-form-item>
              </el-col>
            </el-form>
            <el-form
              v-show="
                JSON.stringify(seeLogData.old.is_verified) !==
                  JSON.stringify(seeLogData.new.is_verified)
              "
            >
              <el-col :span="12">
                <el-form-item label="Гэрээ байгуулсан эсэх:">
                  <el-checkbox v-model="seeLogData.old.is_verified">
                    Гэрээ байгуулсан эсэх
                  </el-checkbox>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Гэрээ байгуулсан эсэх шинэ :">
                  <el-checkbox v-model="seeLogData.new.is_verified">
                    Гэрээ байгуулсан эсэх
                  </el-checkbox>
                </el-form-item>
              </el-col>
            </el-form>
            <el-form
              v-show="
                JSON.stringify(seeLogData.old.is_preOrder) !==
                  JSON.stringify(seeLogData.new.is_preOrder)
              "
            >
              <el-col :span="12">
                <el-form-item label="Урьдчилсан захиалга:">
                  <el-checkbox v-model="seeLogData.old.is_preOrder">
                    Урьдчилсан захиалга
                  </el-checkbox>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Урьдчилсан захиалга шинэ :">
                  <el-checkbox v-model="seeLogData.new.is_preOrder">
                    Урьдчилсан захиалга
                  </el-checkbox>
                </el-form-item>
              </el-col>
            </el-form>
            <el-form
              v-show="
                JSON.stringify(seeLogData.old.is_asap) !==
                  JSON.stringify(seeLogData.new.is_asap)
              "
            >
              <el-col :span="12">
                <el-form-item label="Урьдчилсан захиалга:">
                  <el-checkbox v-model="seeLogData.old.is_asap">
                    Одоо захиалга
                  </el-checkbox>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Одоо захиалга шинэ :">
                  <el-checkbox v-model="seeLogData.new.is_asap">
                    Одоо захиалга
                  </el-checkbox>
                </el-form-item>
              </el-col>
            </el-form>
            <el-form
              v-show="
                JSON.stringify(seeLogData.old.client_category) !==
                  JSON.stringify(seeLogData.new.client_category)
              "
            >
              <el-col :span="12">
                <el-form-item label="Зэрэглэл:">
                  <el-select
                    disabled
                    size="mini"
                    v-model="seeLogData.old.client_category"
                    placeholder="Суртчилгааны байрлал сонгоно уу"
                  >
                    <el-option label="A" value="A"></el-option>
                    <el-option label="B" value="B"></el-option>
                    <el-option label="+B" value="+B"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Зэрэглэл шинэ :">
                  <el-select
                    disabled
                    size="mini"
                    v-model="seeLogData.new.client_category"
                    placeholder="Суртчилгааны байрлал сонгоно уу"
                  >
                    <el-option label="A" value="A"></el-option>
                    <el-option label="B" value="B"></el-option>
                    <el-option label="+B" value="+B"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-form>
            <el-form
              v-show="
                JSON.stringify(seeLogData.old.is_delivery_free) !==
                  JSON.stringify(seeLogData.new.is_delivery_free)
              "
            >
              <el-col :span="12">
                <el-form-item label="Хүргэлтийн төлбөр үнэгүй эсэх:">
                  <el-checkbox v-model="seeLogData.old.is_delivery_free">
                    Хүргэлтийн төлбөр үнэгүй эсэх
                  </el-checkbox>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Хүргэлтийн төлбөр үнэгүй эсэх шинэ :">
                  <el-checkbox v-model="seeLogData.new.is_delivery_free">
                    Хүргэлтийн төлбөр үнэгүй эсэх
                  </el-checkbox>
                </el-form-item>
              </el-col>
            </el-form>
            <el-form
              v-show="
                JSON.stringify(seeLogData.old.badge_start_date) !==
                  JSON.stringify(seeLogData.new.badge_start_date)
              "
            >
              <el-col :span="12">
                <el-form-item label="Баджэ үргэлжлэх хугацаа(Эхлэх):">
                  <el-date-picker
                    type="date"
                    placeholder="Эхлэх огноо"
                    v-model="seeLogData.old.badge_start_date"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    style="width: 100%;"
                  ></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Баджэ үргэлжлэх хугацаа(Эхлэх) шинэ :">
                  <el-date-picker
                    type="date"
                    placeholder="Эхлэх огноо"
                    v-model="seeLogData.new.badge_start_date"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    style="width: 100%;"
                  ></el-date-picker>
                </el-form-item>
              </el-col>
            </el-form>
            <el-form
              v-show="
                JSON.stringify(seeLogData.old.badge_end_date) !==
                  JSON.stringify(seeLogData.new.badge_end_date)
              "
            >
              <el-col :span="12">
                <el-form-item label="Баджэ үргэлжлэх хугацаа(Дуусах):">
                  <el-date-picker
                    type="date"
                    placeholder="Дуусах огноо"
                    v-model="seeLogData.old.badge_end_date"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    style="width: 100%;"
                  ></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Баджэ үргэлжлэх хугацаа(Дуусах) шинэ :">
                  <el-date-picker
                    type="date"
                    placeholder="Дуусах огноо"
                    v-model="seeLogData.new.badge_end_date"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    style="width: 100%;"
                  ></el-date-picker>
                </el-form-item>
              </el-col>
            </el-form>
            <el-form
              v-show="
                JSON.stringify(seeLogData.old.is_delivery_by_outlet) !==
                  JSON.stringify(seeLogData.new.is_delivery_by_outlet)
              "
            >
              <el-col :span="12">
                <el-form-item label="Харилцагч өөрөө хүргэх эсэх:">
                  <el-checkbox v-model="seeLogData.old.is_delivery_by_outlet">
                    Харилцагч өөрөө хүргэх эсэх
                  </el-checkbox>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Харилцагч өөрөө хүргэх эсэх шинэ :">
                  <el-checkbox v-model="seeLogData.new.is_delivery_by_outlet">
                    Харилцагч өөрөө хүргэх эсэх
                  </el-checkbox>
                </el-form-item>
              </el-col>
            </el-form>
            <el-form
              v-show="
                JSON.stringify(seeLogData.old.outlet_delivery_amount) !==
                  JSON.stringify(seeLogData.new.outlet_delivery_amount)
              "
            >
              <el-col :span="12">
                <el-form-item label="Харилцагчийн хүргэлтийн төлбөр:">
                  <el-input-number
                    v-model="seeLogData.old.outlet_delivery_amount"
                    disabled
                  ></el-input-number>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Харилцагчийн хүргэлтийн төлбөр шинэ:">
                  <el-input-number
                    v-model="seeLogData.new.outlet_delivery_amount"
                    disabled
                  ></el-input-number>
                </el-form-item>
              </el-col>
            </el-form>
          </el-row>
        </el-tab-pane>
        <el-tab-pane label="Санхүүгийн мэдээлэл" name="second">
          <el-row :gutter="20">
            <el-form
              v-show="
                JSON.stringify(seeLogData.old.percent) !==
                  JSON.stringify(seeLogData.new.percent)
              "
            >
              <h3>Хүргэлтийн бүс</h3>
              <hr />
              <el-col :span="12">
                <el-form-item label="Шимтгэлийн хувь:">
                  <el-input
                    disabled
                    v-model="seeLogData.old.percent"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Шимтгэлийн хувь шинэ :">
                  <el-input
                    disabled
                    v-model="seeLogData.new.percent"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-form>
            <el-form
              v-show="
                JSON.stringify(seeLogData.old.vat_type) !==
                  JSON.stringify(seeLogData.new.vat_type)
              "
            >
              <el-col :span="12">
                <el-form-item label="Шимтгэлийн хувь:">
                  <el-select disabled v-model="seeLogData.old.vat_type">
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Шимтгэлийн хувь шинэ :">
                  <el-select disabled v-model="seeLogData.new.vat_type">
                  </el-select>
                </el-form-item>
              </el-col>
            </el-form>
            <el-form
              v-show="
                JSON.stringify(seeLogData.old.vat_regno) !==
                  JSON.stringify(seeLogData.new.vat_regno)
              "
            >
              <el-col :span="12">
                <el-form-item label="Регистрийн дугаар:">
                  <el-input disabled v-model="seeLogData.old.vat_regno">
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Регистрийн дугаар шинэ :">
                  <el-input disabled v-model="seeLogData.new.vat_regno">
                  </el-input>
                </el-form-item>
              </el-col>
            </el-form>
            <el-form
              v-show="
                JSON.stringify(seeLogData.old.bank_acount_code) !==
                  JSON.stringify(seeLogData.new.bank_acount_code)
              "
            >
              <el-col :span="12">
                <el-form-item label="Банкны нэр:">
                  <el-select disabled v-model="seeLogData.old.bank_acount_code">
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Банкны нэр шинэ :">
                  <el-select disabled v-model="seeLogData.new.bank_acount_code">
                  </el-select>
                </el-form-item>
              </el-col>
            </el-form>
            <el-form
              v-show="
                JSON.stringify(seeLogData.old.bank_account_number) !==
                  JSON.stringify(seeLogData.new.bank_account_number)
              "
            >
              <el-col :span="12">
                <el-form-item label="Дансны дугаар:">
                  <el-input
                    disabled
                    v-model="seeLogData.old.bank_account_number"
                  >
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Дансны дугаар шинэ :">
                  <el-input
                    disabled
                    v-model="seeLogData.new.bank_account_number"
                  >
                  </el-input>
                </el-form-item>
              </el-col>
            </el-form>
            <el-form
              v-show="
                JSON.stringify(seeLogData.old.bank_account_name) !==
                  JSON.stringify(seeLogData.new.bank_account_name)
              "
            >
              <el-col :span="12">
                <el-form-item label="Дансны нэр:">
                  <el-input disabled v-model="seeLogData.old.bank_account_name">
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Дансны нэр шинэ :">
                  <el-input disabled v-model="seeLogData.new.bank_account_name">
                  </el-input>
                </el-form-item>
              </el-col>
            </el-form>
            <el-form
              v-show="
                JSON.stringify(seeLogData.old.company_name) !==
                  JSON.stringify(seeLogData.new.company_name)
              "
            >
              <el-col :span="12">
                <el-form-item label="Байгуулгын нэр:">
                  <el-input disabled v-model="seeLogData.old.company_name">
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Байгуулгын нэр шинэ :">
                  <el-input disabled v-model="seeLogData.new.company_name">
                  </el-input>
                </el-form-item>
              </el-col>
            </el-form>
            <el-form
              v-show="
                JSON.stringify(seeLogData.old.d_code) !==
                  JSON.stringify(seeLogData.new.d_code)
              "
            >
              <el-col :span="12">
                <el-form-item label="Даймонд код:">
                  <el-input disabled v-model="seeLogData.old.d_code">
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Даймонд код шинэ :">
                  <el-input disabled v-model="seeLogData.new.d_code">
                  </el-input>
                </el-form-item>
              </el-col>
            </el-form>
          </el-row>
        </el-tab-pane>
        <el-tab-pane label="Хүргэлтийн бүс" name="third">
          <el-row
            v-show="
              JSON.stringify(seeLogData.old.hexagon_zone) !==
                JSON.stringify(seeLogData.new.hexagon_zone)
            "
          >
            <h3>Хүргэлтийн бүс</h3>
            <hr />
            <el-col :span="12">
              <div class="grid-content bg-purple">
                <div class="partner-google-map">
                  <h3 style="text-align: center">Хуучин хүргэлтийн бүс</h3>
                  <el-radio-group v-model="hexagon_radio">
                    <el-radio label="outlet_location"
                      >Харилцагчийн байршил</el-radio
                    >
                    <el-radio label="allowed_list">Үндсэн бүс</el-radio>
                    <el-radio label="blocked_list">Хязгаарласан бүс</el-radio>
                  </el-radio-group>
                  <gmap-map
                    v-if="seeLogData"
                    ref="map"
                    :center="center"
                    :zoom="gmapDefaultZoom"
                    :options="mapOptions"
                    style="width:100%; height: 100%; margin-top: 5px;"
                  >
                    <gmap-marker :position="this.location" :clickable="true" />
                    <div v-if="hexagon_radio === 'blocked_list'">
                      <gmap-polygon
                        v-for="(item, index) in oldActivePolygons"
                        :key="index"
                        :paths="item.points"
                        :options="{
                          fillColor: getPolygonFillColor(
                            'blocked_list',
                            item.label,
                            'old'
                          ),
                          strokeOpacity: 0.2,
                          fillOpacity: getPolygonFillOpacity(
                            'blocked_list',
                            item.label,
                            'old'
                          )
                            ? 0.3
                            : 0.1
                        }"
                        :editable="item.editable"
                      >
                      </gmap-polygon>
                    </div>
                    <div v-if="hexagon_radio === 'blocked_list'">
                      <gmap-polygon
                        v-for="(item, index) in getDefaultHexagonList(
                          'blocked_list'
                        )"
                        :key="index"
                        :paths="item.points"
                        :options="{
                          fillColor: '#00FF00',
                          strokeColor: '#00FF00',
                          strokeOpacity: 0.2,
                          fillOpacity: 0,
                          clickable: false
                        }"
                      >
                      </gmap-polygon>
                    </div>
                    <div v-if="hexagon_radio === 'allowed_list'">
                      <gmap-polygon
                        v-for="(item, index) in getDefaultHexagonList(
                          'allowed_list'
                        )"
                        :key="index"
                        :paths="item.points"
                        :options="{
                          fillColor: getPolygonFillColor(
                            'allowed_list',
                            item.label,
                            'old'
                          ),
                          strokeOpacity: 0.2,
                          fillOpacity: getPolygonFillOpacity(
                            'allowed_list',
                            item.label,
                            'old'
                          )
                            ? 0.5
                            : 0.1
                        }"
                        :editable="item.editable"
                      >
                      </gmap-polygon>
                    </div>
                  </gmap-map>
                </div>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="grid-content bg-purple-light">
                <div class="partner-google-map">
                  <h3 style="text-align: center">Шинэ хүргэлтийн бүс</h3>
                  <el-radio-group v-model="hexagon_radio">
                    <el-radio label="outlet_location"
                      >Харилцагчийн байршил</el-radio
                    >
                    <el-radio label="allowed_list">Үндсэн бүс</el-radio>
                    <el-radio label="blocked_list">Хязгаарласан бүс</el-radio>
                  </el-radio-group>
                  <gmap-map
                    v-if="seeLogData"
                    ref="map"
                    :center="center"
                    :zoom="gmapDefaultZoom"
                    :options="mapOptions"
                    style="width:100%;  height: 100%; margin-top: 5px;"
                  >
                    <gmap-marker :position="this.location" :clickable="true" />
                    <div v-if="hexagon_radio === 'blocked_list'">
                      <gmap-polygon
                        v-for="(item, index) in oldActivePolygons"
                        :key="index"
                        :paths="item.points"
                        :options="{
                          fillColor: getPolygonFillColor(
                            'blocked_list',
                            item.label,
                            'new'
                          ),
                          strokeOpacity: 0.2,
                          fillOpacity: getPolygonFillOpacity(
                            'blocked_list',
                            item.label,
                            'new'
                          )
                            ? 0.3
                            : 0.1
                        }"
                        :editable="item.editable"
                      >
                      </gmap-polygon>
                    </div>
                    <div v-if="hexagon_radio === 'blocked_list'">
                      <gmap-polygon
                        v-for="(item, index) in getDefaultHexagonList(
                          'blocked_list'
                        )"
                        :key="index"
                        :paths="item.points"
                        :options="{
                          fillColor: '#00FF00',
                          strokeColor: '#00FF00',
                          strokeOpacity: 0.2,
                          fillOpacity: 0,
                          clickable: false
                        }"
                      >
                      </gmap-polygon>
                    </div>
                    <div v-if="hexagon_radio === 'allowed_list'">
                      <gmap-polygon
                        v-for="(item, index) in getDefaultHexagonList(
                          'allowed_list'
                        )"
                        :key="index"
                        :paths="item.points"
                        :options="{
                          fillColor: getPolygonFillColor(
                            'allowed_list',
                            item.label,
                            'new'
                          ),
                          strokeOpacity: 0.2,
                          fillOpacity: getPolygonFillOpacity(
                            'allowed_list',
                            item.label,
                            'new'
                          )
                            ? 0.5
                            : 0.1
                        }"
                        :editable="item.editable"
                      >
                      </gmap-polygon>
                    </div>
                  </gmap-map>
                </div>
              </div>
            </el-col>
          </el-row>
        </el-tab-pane>
        <el-tab-pane label="Цагийн хуваарь" name="fourth">
          <el-row :gutter="0">
            <el-form
              v-show="
                JSON.stringify(seeLogData.old.time_mon) !==
                  JSON.stringify(seeLogData.new.time_mon)
              "
            >
              <h3>Цагийн хуваарь</h3>
              <el-col :span="12">
                <el-form-item label="Даваа:">
                  <el-time-picker
                    style="width:30%"
                    size="mini"
                    v-model="seeLogData.old.time_mon.startTime"
                    value-format="HH:mm"
                    disabled
                  >
                  </el-time-picker>
                  <el-time-picker
                    style="width:30%"
                    size="mini"
                    v-model="seeLogData.old.time_mon.endTime"
                    value-format="HH:mm"
                    disabled
                  >
                  </el-time-picker>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item>
                  <el-time-picker
                    style="width:30%"
                    size="mini"
                    v-model="seeLogData.new.time_mon.startTime"
                    value-format="HH:mm"
                    disabled
                  >
                  </el-time-picker>
                  <el-time-picker
                    style="width:30%"
                    size="mini"
                    v-model="seeLogData.new.time_mon.endTime"
                    value-format="HH:mm"
                    disabled
                  >
                  </el-time-picker>
                </el-form-item>
              </el-col>
            </el-form>
            <el-form
              v-show="
                JSON.stringify(seeLogData.old.time_tue) !==
                  JSON.stringify(seeLogData.new.time_tue)
              "
            >
              <el-col :span="12">
                <el-form-item label="Мягмар:">
                  <el-time-picker
                    style="width:30%"
                    size="mini"
                    v-model="seeLogData.old.time_tue.startTime"
                    value-format="HH:mm"
                    disabled
                  >
                  </el-time-picker>
                  <el-time-picker
                    style="width:30%"
                    size="mini"
                    v-model="seeLogData.old.time_tue.endTime"
                    value-format="HH:mm"
                    disabled
                  >
                  </el-time-picker>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item>
                  <el-time-picker
                    style="width:30%"
                    size="mini"
                    v-model="seeLogData.new.time_tue.startTime"
                    value-format="HH:mm"
                    disabled
                  >
                  </el-time-picker>
                  <el-time-picker
                    style="width:30%"
                    size="mini"
                    v-model="seeLogData.new.time_tue.endTime"
                    value-format="HH:mm"
                    disabled
                  >
                  </el-time-picker>
                </el-form-item>
              </el-col>
            </el-form>
            <el-form
              v-show="
                JSON.stringify(seeLogData.old.time_wed) !==
                  JSON.stringify(seeLogData.new.time_wed)
              "
            >
              <el-col :span="12">
                <el-form-item label="Лхагва:">
                  <el-time-picker
                    style="width:30%"
                    size="mini"
                    v-model="seeLogData.old.time_wed.startTime"
                    value-format="HH:mm"
                    disabled
                  >
                  </el-time-picker>
                  <el-time-picker
                    style="width:30%"
                    size="mini"
                    v-model="seeLogData.old.time_wed.endTime"
                    value-format="HH:mm"
                    disabled
                  >
                  </el-time-picker>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item>
                  <el-time-picker
                    style="width:30%"
                    size="mini"
                    v-model="seeLogData.new.time_wed.startTime"
                    value-format="HH:mm"
                    disabled
                  >
                  </el-time-picker>
                  <el-time-picker
                    style="width:30%"
                    size="mini"
                    v-model="seeLogData.new.time_wed.endTime"
                    value-format="HH:mm"
                    disabled
                  >
                  </el-time-picker>
                </el-form-item>
              </el-col>
            </el-form>
            <el-form
              v-show="
                JSON.stringify(seeLogData.old.time_thur) !==
                  JSON.stringify(seeLogData.new.time_thur)
              "
            >
              <el-col :span="12">
                <el-form-item label="Пүрэв:">
                  <el-time-picker
                    style="width:30%"
                    size="mini"
                    v-model="seeLogData.old.time_thur.startTime"
                    value-format="HH:mm"
                    disabled
                  >
                  </el-time-picker>
                  <el-time-picker
                    style="width:30%"
                    size="mini"
                    v-model="seeLogData.old.time_thur.endTime"
                    value-format="HH:mm"
                    disabled
                  >
                  </el-time-picker>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item>
                  <el-time-picker
                    style="width:30%"
                    size="mini"
                    v-model="seeLogData.new.time_thur.startTime"
                    value-format="HH:mm"
                    disabled
                  >
                  </el-time-picker>
                  <el-time-picker
                    style="width:30%"
                    size="mini"
                    v-model="seeLogData.new.time_thur.endTime"
                    value-format="HH:mm"
                    disabled
                  >
                  </el-time-picker>
                </el-form-item>
              </el-col>
            </el-form>
            <el-form
              v-show="
                JSON.stringify(seeLogData.old.time_fri) !==
                  JSON.stringify(seeLogData.new.time_fri)
              "
            >
              <el-col :span="12">
                <el-form-item label="Баасан:">
                  <el-time-picker
                    style="width:30%"
                    size="mini"
                    v-model="seeLogData.old.time_fri.startTime"
                    value-format="HH:mm"
                    disabled
                  >
                  </el-time-picker>
                  <el-time-picker
                    style="width:30%"
                    size="mini"
                    v-model="seeLogData.old.time_fri.endTime"
                    value-format="HH:mm"
                    disabled
                  >
                  </el-time-picker>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item>
                  <el-time-picker
                    style="width:30%"
                    size="mini"
                    v-model="seeLogData.new.time_fri.startTime"
                    value-format="HH:mm"
                    disabled
                  >
                  </el-time-picker>
                  <el-time-picker
                    style="width:30%"
                    size="mini"
                    v-model="seeLogData.new.time_fri.endTime"
                    value-format="HH:mm"
                    disabled
                  >
                  </el-time-picker>
                </el-form-item>
              </el-col>
            </el-form>
            <el-form
              v-show="
                JSON.stringify(seeLogData.old.time_sat) !==
                  JSON.stringify(seeLogData.new.time_sat)
              "
            >
              <el-col :span="12">
                <el-form-item label="Бямба:">
                  <el-time-picker
                    style="width:30%"
                    size="mini"
                    v-model="seeLogData.old.time_sat.startTime"
                    value-format="HH:mm"
                    disabled
                  >
                  </el-time-picker>
                  <el-time-picker
                    style="width:30%"
                    size="mini"
                    v-model="seeLogData.old.time_sat.endTime"
                    value-format="HH:mm"
                    disabled
                  >
                  </el-time-picker>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item>
                  <el-time-picker
                    style="width:30%"
                    size="mini"
                    v-model="seeLogData.new.time_sat.startTime"
                    value-format="HH:mm"
                    disabled
                  >
                  </el-time-picker>
                  <el-time-picker
                    style="width:30%"
                    size="mini"
                    v-model="seeLogData.new.time_sat.endTime"
                    value-format="HH:mm"
                    disabled
                  >
                  </el-time-picker>
                </el-form-item>
              </el-col>
            </el-form>
            <el-form
              v-show="
                JSON.stringify(seeLogData.old.time_sun) !==
                  JSON.stringify(seeLogData.new.time_sun)
              "
            >
              <el-col :span="12">
                <el-form-item label="Ням:">
                  <el-time-picker
                    style="width:30%"
                    size="mini"
                    v-model="seeLogData.old.time_sun.startTime"
                    value-format="HH:mm"
                    disabled
                  >
                  </el-time-picker>
                  <el-time-picker
                    style="width:30%"
                    size="mini"
                    v-model="seeLogData.old.time_sun.endTime"
                    value-format="HH:mm"
                    disabled
                  >
                  </el-time-picker>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item>
                  <el-time-picker
                    style="width:30%"
                    size="mini"
                    v-model="seeLogData.new.time_sun.startTime"
                    value-format="HH:mm"
                    disabled
                  >
                  </el-time-picker>
                  <el-time-picker
                    style="width:30%"
                    size="mini"
                    v-model="seeLogData.new.time_sun.endTime"
                    value-format="HH:mm"
                    disabled
                  >
                  </el-time-picker>
                </el-form-item>
              </el-col>
            </el-form>
          </el-row>
        </el-tab-pane>
        <el-tab-pane label="Гэрээний мэдээлэл" name="fifth">
          <el-row :gutter="20">
            <el-form
              v-show="
                JSON.stringify(seeLogData.old.contract_is_paid) !==
                  JSON.stringify(seeLogData.new.contract_is_paid)
              "
            >
              <el-col :span="12">
                <el-form-item label="Гэрээний төлбөр төлсөн эсэх">
                  <el-checkbox
                    v-model="seeLogData.old.contract_is_paid"
                    disabled
                  ></el-checkbox>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Гэрээний төлбөр төлсөн эсэх шинэ">
                  <el-checkbox
                    v-model="seeLogData.new.contract_is_paid"
                    disabled
                  ></el-checkbox>
                </el-form-item>
              </el-col>
            </el-form>
            <el-form
              v-show="
                JSON.stringify(seeLogData.old.contract_expire_at) !==
                  JSON.stringify(seeLogData.new.contract_expire_at)
              "
            >
              <el-col :span="12">
                <el-form-item label="Гэрээний дуусах хугацаа">
                  <el-input
                    style="width: 300px; height: 100px"
                    v-model="seeLogData.old.contract_expire_at"
                    disabled
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Гэрээний дуусах хугацаа шинэ">
                  <el-input
                    style="width: 300px; height: 100px"
                    v-model="seeLogData.new.contract_expire_at"
                    disabled
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-form>
            <el-form
              v-show="
                JSON.stringify(seeLogData.old.contract_discount_percent) !==
                  JSON.stringify(seeLogData.new.contract_discount_percent)
              "
            >
              <el-col :span="12">
                <el-form-item label="Гэрээний хөнгөлөлт %">
                  <el-input
                    style="width: 300px; height: 100px"
                    v-model="seeLogData.old.contract_discount_percent"
                    disabled
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Гэрээний хөнгөлөлт % шинэ">
                  <el-input
                    style="width: 300px; height: 100px"
                    v-model="seeLogData.new.contract_discount_percent"
                    disabled
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-form>
            <el-form>
              <el-col :span="12">
                <el-form-item label="Зассан шалтгаан">
                  <el-input
                    style="width: 400px; height: 100px"
                    v-model="seeLogData.desc"
                    disabled
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-form>
          </el-row>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>
  </div>
</template>
<script>
import { bus } from "../main";
import {
  geoToH3,
  h3ToChildren,
  h3ToGeoBoundary,
  getDestinationH3IndexFromUnidirectionalEdge,
  getH3UnidirectionalEdgesFromHexagon
} from "h3-js";
import Base64Upload from "vue-base64-upload";
import { checkProperty } from "../helpers/helper";
const polygons = require("../assets/polygons.json");
const mapMarker = require("../assets/marker-a.png");
export default {
  props: {
    seeLogData: Object,
    showLogSeeDetailDialog: Boolean
  },
  components: {
    Base64Upload
  },
  created() {
    this.calculatePolygon();
    this.calculateNewPolygon();
  },
  data() {
    return {
      activeName: "first",
      prePolygons: [],
      selPrePol: null,
      selPolygon: [],
      polygons: polygons,
      rawText1: "",
      polygonform: {
        id: "",
        location: "",
        polygon: [],
        user: ""
      },
      options: {
        scrollwheel: true,
        mapTypeControl: false,
        streetViewControl: false,
        minZoom: 4,
        maxZoom: 20,
        gestureHandling: "greedy"
      },
      location: {
        lat: 47.9174,
        lng: 106.9137
      },
      markerOptions: {
        url: mapMarker,
        size: { width: 100, height: 90, f: "px", b: "px" },
        scaledSize: { width: 45, height: 45, f: "px", b: "px" }
      },
      center: {
        lat: 47.9174,
        lng: 106.9137
      },
      points: [
        { lat: 47.915773225034776, lng: 106.91600178903806 },
        { lat: 47.92363207137343, lng: 106.89498393967285 },
        { lat: 47.92698592131538, lng: 106.93144588588865 }
      ],
      optionsPoly: {
        fillColor: "#000000",
        strokeColor: "#FF0000",
        strokeOpacity: 0.9,
        fillOpacity: 0.1
      },
      newPolygonform: {
        id: "",
        location: "",
        polygon: [],
        user: ""
      },
      newPoints: [
        { lat: 47.915773225034776, lng: 106.91600178903806 },
        { lat: 47.92363207137343, lng: 106.89498393967285 },
        { lat: 47.92698592131538, lng: 106.93144588588865 }
      ],
      newLocation: {
        lat: 47.9174,
        lng: 106.9137
      },
      gmapDefaultZoom: 13,
      mapOptions: {
        scrollwheel: true,
        mapTypeControl: true,
        streetViewControl: false,
        minZoom: 8,
        maxZoom: 16,
        gestureHandling: "greedy"
      },
      hexagon_radio: "allowed_list",
      hexagonResourceSize: 8,
      hexagonTable: {
        "20": 14,
        "19": 13,
        "18": 12,
        "17": 11,
        "16": 10,
        "15": 9,
        "14": 8,
        "13": 7,
        "12": 6,
        "11": 5,
        "10": 4,
        "9": 3,
        "8": 2
      }
    };
  },
  async mounted() {
    setTimeout(async () => {
      this.$refs.map.$on("zoom_changed", zoomSize => {
        this.hexagonResourceSize = this.hexagonTable[zoomSize];
      });
    }, 5000);
  },
  computed: {
    oldActivePolygons: {
      cache: false,
      get() {
        if (this.seeLogData) {
          if (this.hexagon_radio === "allowed_list") {
            return this.getDefaultHexagonList();
          }
          if (this.hexagon_radio === "blocked_list") {
            let tempData = [];
            this.seeLogData.old.hexagon_zone.allowed_list.forEach(el => {
              // const tempHexagonList = [];
              let hexagons = h3ToChildren(el, this.hexagonResourceSize + 1);
              let tempHexagonList = [];
              hexagons.forEach(hexagon => {
                tempHexagonList = tempHexagonList.concat(
                  this.getNeighbourHexagons(hexagon)
                );
              });
              hexagons = hexagons.concat(tempHexagonList);
              hexagons = [...new Set(hexagons)];
              const calculatedH3 = this.generatePolygonsFromHexagonList(
                hexagons
              );
              tempData = tempData.concat(calculatedH3);
            });
            if (
              checkProperty(this.seeLogData.old, "hexagon_zone") &&
              checkProperty(this.seeLogData.old.hexagon_zone, "blocked_list")
            ) {
              tempData = tempData.concat(
                this.generatePolygonsFromHexagonList(
                  this.seeLogData.old.hexagon_zone.blocked_list
                )
              );
            }
            return tempData;
          }
        }
        return this.getDefaultHexagonList();
      }
    }
  },
  methods: {
    closeDialog() {
      bus.$emit("closeLogDialog", false);
      //   this.showLogSeeDetailDialog = false;
    },
    calculatePolygon() {
      let arrPoints = [];
      let arrPolygon = [];
      if (this.seeLogData.old.location) {
        this.location = {
          lat: parseFloat(this.seeLogData.old.location.split(",")[0]),
          lng: parseFloat(this.seeLogData.old.location.split(",")[1])
        };
      }
      if (this.seeLogData.new.location) {
        this.location = {
          lat: parseFloat(this.seeLogData.new.location.split(",")[0]),
          lng: parseFloat(this.seeLogData.new.location.split(",")[1])
        };
      }
      if (this.seeLogData.old.hasOwnProperty("polygon1")) {
        this.polygonform.polygon = this.seeLogData.old.polygon1.coordinates[0];
        this.seeLogData.old.polygon1.coordinates[0].forEach(element => {
          arrPoints.push({ lat: element[1], lng: element[0] });
        });
        this.points = arrPoints;
        if (this.seeLogData.old.location) {
          this.location = {
            lat: parseFloat(this.seeLogData.old.location.split(",")[0]),
            lng: parseFloat(this.seeLogData.old.location.split(",")[1])
          };
        }
        this.polygonform.polygon = this.seeLogData.old.polygon1.coordinates[0];
      } else {
        this.points.forEach(element => {
          arrPolygon.push([element.lng, element.lat]);
        });
        this.polygonform.polygon = arrPolygon;
      }
    },
    calculateNewPolygon() {
      let arrPoints = [];
      let arrPolygon = [];
      if (this.seeLogData.new.location) {
        this.newLocation = {
          lat: parseFloat(this.seeLogData.new.location.split(",")[0]),
          lng: parseFloat(this.seeLogData.new.location.split(",")[1])
        };
      }
      if (this.seeLogData.new.hasOwnProperty("polygon1")) {
        this.newPolygonform.polygon = this.seeLogData.new.polygon1.coordinates[0];
        this.seeLogData.new.polygon1.coordinates[0].forEach(element => {
          arrPoints.push({ lat: element[1], lng: element[0] });
        });
        this.newPoints = arrPoints;
        if (this.seeLogData.new.location) {
          this.newLocation = {
            lat: parseFloat(this.seeLogData.new.location.split(",")[0]),
            lng: parseFloat(this.seeLogData.new.location.split(",")[1])
          };
        }
        this.newPolygonform.polygon = this.seeLogData.new.polygon1.coordinates[0];
      } else {
        this.newPoints.forEach(element => {
          arrPolygon.push([element.lng, element.lat]);
        });
        this.newPolygonform.polygon = arrPolygon;
      }
    },
    getDefaultHexagonList(from) {
      let tempHexagonList = [];
      if (this.seeLogData) {
        if (this.seeLogData.old.location) {
          const outletHexagon = geoToH3(
            this.seeLogData.old.location.split(",")[0],
            this.seeLogData.old.location.split(",")[1],
            8
          );
          tempHexagonList = [...this.getNeighbourHexagons(outletHexagon)];
          if (
            checkProperty(this.seeLogData.old, "hexagon_zone") &&
            checkProperty(this.seeLogData.old.hexagon_zone, "allowed_list")
          ) {
            this.seeLogData.old.hexagon_zone.allowed_list.forEach(el => {
              tempHexagonList = tempHexagonList.concat(
                this.getNeighbourHexagons(el)
              );
            });
          }
        }
      }
      if (from === "blocked_list") {
        if (
          checkProperty(this.seeLogData.old, "hexagon_zone") &&
          checkProperty(this.seeLogData.old.hexagon_zone, "allowed_list")
        ) {
          return this.generatePolygonsFromHexagonList(
            this.seeLogData.old.hexagon_zone.allowed_list
          );
        }
      }
      const calculatedH3List = this.generatePolygonsFromHexagonList(
        this.seeLogData.old.hexagon_zone.blocked_list
      ).concat(this.generatePolygonsFromHexagonList(tempHexagonList));
      return calculatedH3List;
    },
    getPolygonFillColor(type, h3, label) {
      if (label === "old") {
        if (
          type === "allowed_list" &&
          this.seeLogData.old.hexagon_zone.blocked_list.includes(h3)
        ) {
          return "#FF0000";
        }
        if (
          checkProperty(this.seeLogData.old, "hexagon_zone") &&
          checkProperty(this.seeLogData.old.hexagon_zone, type) &&
          this.seeLogData.old.hexagon_zone[type].includes(h3)
        ) {
          return "#000000";
        }
        return "#FFFFFF";
      }
      if (label === "new") {
        if (
          type === "allowed_list" &&
          this.seeLogData.new.hexagon_zone.blocked_list.includes(h3)
        ) {
          return "#FF0000";
        }
        if (
          checkProperty(this.seeLogData.new, "hexagon_zone") &&
          checkProperty(this.seeLogData.new.hexagon_zone, type) &&
          this.seeLogData.new.hexagon_zone[type].includes(h3)
        ) {
          return "#000000";
        }
        return "#FFFFFF";
      }
    },
    getNeighbourHexagons(h3) {
      let tempList = [];
      tempList.push(h3);
      const edges = getH3UnidirectionalEdgesFromHexagon(h3);
      edges.forEach(el => {
        const calculatedH3 = getDestinationH3IndexFromUnidirectionalEdge(el);
        tempList.push(calculatedH3);
      });
      return tempList;
    },
    getPolygonFillOpacity(type, h3, label) {
      if (label === "old") {
        if (
          type === "allowed_list" &&
          this.seeLogData.old.hexagon_zone.blocked_list.includes(h3)
        ) {
          return true;
        }
        if (
          checkProperty(this.seeLogData.old, "hexagon_zone") &&
          checkProperty(this.seeLogData.old.hexagon_zone, type) &&
          this.seeLogData.old.hexagon_zone[type].includes(h3)
        ) {
          return true;
        }
        return false;
      }
      if (label === "new") {
        if (
          type === "allowed_list" &&
          this.seeLogData.new.hexagon_zone.blocked_list.includes(h3)
        ) {
          return true;
        }
        if (
          checkProperty(this.seeLogData.new, "hexagon_zone") &&
          checkProperty(this.seeLogData.new.hexagon_zone, type) &&
          this.seeLogData.new.hexagon_zone[type].includes(h3)
        ) {
          return true;
        }
        return false;
      }
    },
    generatePolygonsFromHexagonList(hexagonList) {
      hexagonList = [...new Set(hexagonList)];
      const tempData = [];
      hexagonList.forEach(el => {
        const polygon = h3ToGeoBoundary(el);
        let temp = {
          label: el,
          points: []
        };
        polygon.forEach(item => {
          temp.points.push({
            lat: item[0],
            lng: item[1]
          });
        });
        tempData.push(temp);
      });
      return tempData;
    }
  }
};
</script>
